import React, {Component} from "react";
import { Container } from "../../components";
import "../../App.css"
import "./feature.css";
import featuresbanner from "../../images/assets/featuresbanner.png"
import expandarrow from "../../icons/expandarrow.png"
import section1 from "../../images/assets/section.png"
import section2 from "../../images/assets/section2.png"
import section3 from "../../images/assets/section3.png"

class Feature extends Component {
  render() {
    const { history } = this.props;
    return (
        <Container active="feature" history={history} footer={true}>
        <div className="Mask1">
          <div className="container">
            <h3 className="whiteColor">Sell more products, earn more and grow your business</h3>
            <div className="smallBox whiteColor" >
              <h4>Increase your revenue</h4>
              <p>From aggregate insight of customers to 0% charge for all the manual payment option, we provide huge number of features you need to increase your revenue.</p>
              <div><a href="#box1"><img src={expandarrow} className="expand-arrow" /></a></div>  
            </div>
            <div className="smallBox whiteColor" >
              <h4>Save more time</h4>
              <p>With automated process for the team, get all the information on team's work to customer orders in mobile & web which you can check any time.</p>
              <div><a href="#hell"><img src={expandarrow} className="expand-arrow" /></a></div>  
            </div> 
            <div className="smallBox whiteColor" >
              <h4>Connect with more buyers</h4>
              <p>Mobile app and web just to do selling. On board Customers. Engage customers. Sell the products. All at scale.</p>
              <div><a href="#hel"><img src={expandarrow} className="expand-arrow" /></a></div>  
            </div>        
          </div>
          <div><img src={featuresbanner} className="features-banner"/></div>  
      </div>
      <div className="Mask2" id={'box1'}>
        <img src={section1}  className="section1"></img>
        <h2 style={{padding:10}}>Increase your revenue</h2>
        <div className="Mask3">
          <div className="box">
            <h4 className="secondaryColor">Multiple Payment Gateways</h4>
            <p>Choose any payment gateway or manual payment processing. We support all NEFT, Cheque, EMI,COD, in store payment etc...</p>
          </div>
          <div className="box">
            <h4 className="secondaryColor">List and sell</h4>
            <p>Sell inventory which is not with you</p>
          </div>
          <div className="box">
            <h4 className="secondaryColor">B2B sales approach</h4>
            <p>Negotiate Price. Give Discount Coupon.</p>
          </div>
          <div className="box">
            <h4 className="secondaryColor">Sell more</h4>
            <p>Insights to sell more</p>
          </div>
          <div className="box">
            <h4 className="secondaryColor">Simple Refunds</h4>
            <p>Process refunds in a click or transfer it as points in your customer account.</p>
          </div>
          <button className="primaryButton" onClick={()=>history.push("/register")}>Register</button>
        </div>
      </div>
    </Container>
        );
    }
}
export default Feature;    