import React, { Component } from "react";
import "../../App.css";
import "./footer.css";
import fb from "../../images/fb/fb.png";
import youtube from "../../images/youtube/youtube.png";
import instagram from "../../images/instagram/instagram.png";
import twitter from "../../images/twitter/twitter.png";
import { NONAME } from "dns";


export default class Footer extends Component {
    render(){
        const { hide, active="home" } = this.props;
        const { history } = this.props;
        return(
            <div style={{display: hide? "none" : "block", backgroundColor: "#33475b"}}>
              <div className="container">
                <div className="footer">
                    <div className="footer-logo">
                        <div className="te">Te</div>
                        <div className="ve">Ve</div>
                    </div>
                    <div className="footer-page-route">
                        <div className={active==="home" ? "footer-page-link foo-active" : "footer-page-link"} onClick={()=>history.push("/home")}>
                            Home
                        </div>
                        {/* <div className={active==="feature" ? "footer-page-link foo-active" : "footer-page-link"} onClick={()=>history.push("/features")}>
                            Features
                        </div> */}
                        <div className={active==="contactus" ? "footer-page-link foo-active" : "footer-page-link"} onClick={()=>history.push("/contact")}>
                            Contact Us
                        </div>
                    </div>
                    <div>
                        <button className="primaryButton anythingCenter" onClick={()=>{history.push("/register");window.scrollTo(0,0);}}>Register</button>
                    </div>
                </div>
                <div className="footer">
                    <div className="foo-contact-number">
                        <p style={{marginRight: "41px"}}>contact@moonproduct.in</p>
                        <p><a href="tel:+919791848091" style={{color:"#00bda5"}}>+91 9791848091</a></p>
                    </div>
                </div>
                {/* <div className="footer">
                    <div style={{marginLeft: "auto", marginRight: "auto", marginTop: 21}}>
                        <img src={youtube} style={{marginLeft: 15}}/>
                        <img src={fb} style={{marginLeft: 15}}/>
                        <img src={instagram} style={{marginLeft: 15}}/>
                        <img src={twitter} style={{marginLeft: 15}}/>
                    </div>
                </div> */}
                <div className="footer">
                    <text className="copyright">© 2018 MoonProduct</text>
                </div>
                <div className="footer"></div>
                </div>
            </div>
        );
    }
}