import { endpointConstants } from "../constants";
import GLOBALS from "../Globals";

const initialState = {
  service_url: GLOBALS.ENDPOINTS.URI,
  x_cloud_id: GLOBALS.X_CLOUD_ID,
  status: "IDLE"
};

export function endpoint(state = initialState, action) {
  switch (action.type) {
    case endpointConstants.HELLO_REQUEST:
      return {
        ...state,
        status: "HELLO_REQUEST"
      };
    case endpointConstants.HELLO_SUCCESS:
      return {
        ...state,
        service_url:  GLOBALS.ENDPOINTS.URI,
        x_cloud_id: action.res.cloud_id,
        status: "HELLO_SUCCESS"
      };
    default:
      return state;
  }
}
