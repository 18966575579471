import React, {Component} from "react";
import { connect } from "react-redux";
import { Container, Header } from "../../components";
import "./register.css";
import Cookies from "universal-cookie";
import { registerActions } from "../../actions/register.actions";

class registerPage extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      phone: ""
    };
  }

  componentDidUpdate(prevProps){
    const { dispatch, history, register } = this.props;
    if(this.props.register != prevProps.register){
      // if(register.status=="REGISTER_SUCCESS"){
      //   alert("Thanks for contacting us! We will call you in couple of days");
      //   history.push("/home");
      //   window.scrollTo(0,0);
      // }
    }
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
    let property = document.getElementsByName(event.target.name)[0];
    property.style.border = "0.5px solid rgb(219,219,219)";
  };

  handleSubmit = () => {
    const { dispatch } = this.props;
    let cookie =  new Cookies();
    if (
      this.state.name != "" &&
      this.state.email != "" &&
      this.state.phone != ""
    ) {
      let property = document.getElementsByName("name")[0];
      let property1 = document.getElementsByName("phone")[0];
      let property2 = document.getElementsByName("email")[0];
      property.style.border = "0.5px solid rgb(219,219,219)";
      property1.style.border = "0.5px solid rgb(219,219,219)";
      property2.style.border = "0.5px solid rgb(219,219,219)";

      let payload = {
        email: this.state.email,
        mobile: this.state.phone,
        title: this.state.name,
        status: 1
      };
      dispatch(registerActions.register(payload));
    } else {
      if (this.state.name == "") {
        let property = document.getElementsByName("name")[0];
        property.style.border = "0.7px solid rgb(255,0,0)";
        property.placeholder = "Please enter your Name";
      }
      if (this.state.phone == "") {
        let property = document.getElementsByName("phone")[0];
        property.style.border = "0.7px solid rgb(255,0,0)";
        property.placeholder = "Please enter your Phone Number";
      }
      if (this.state.email == "") {
        let property = document.getElementsByName("email")[0];
        property.style.border = "0.7px solid rgb(255,0,0)";
        property.placeholder = "Please enter your Email ID";
      }
    }
  };

  render() {
    const { dispatch, history, register } = this.props;
    return (
      <div className="orangeBg">
        <div className="container header">
          <div className="logoR">
            <p style={{margin:0}} className="whiteColor" onClick={()=>{history.push("/home")}}>Teve</p>
          </div>  
        </div>
        <div className="container">        
          <h1 className="whiteColor centerText">Sell more products and grow your business</h1>
          <div className="boxM">
            <div className="centerText">
          {register.status=="REGISTER_SUCCESS" &&
            <div>
              <p>Thanks for contacting us! We will call you in couple of days</p>
              <button className="secondaryButton anythingCenter" onClick={()=>{history.push("/home")}}>Go to home</button>
            </div>
          } 
          {register.status!="REGISTER_SUCCESS" &&
            <div>
              <input type="text" placeholder="Enter your name" className="input" name="name" onChange={this.handleChange}></input>
              <input type="email" placeholder="Enter your Email id" className="input" name="email" onChange={this.handleChange}></input>
              <input type="number" placeholder="Enter your phone number" className="input" name="phone" onChange={this.handleChange}></input>
              <button className="primaryButton anythingCenter" onClick={this.handleSubmit}>Register</button>
            </div>
          }
            </div>
          </div>
          <p className="whiteColor centerText chumma">© 2019 MoonProduct</p>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { register } = state;

  return {
    register
  };
}
export default connect(mapStateToProps)(registerPage);
