import React, {Component} from "react";
import { Container } from "../../components";
import "./contact-us.css";
import "../../App.css";
import contactmask  from "../../images/background/contactmask.png"
import officeicon from "../../icons/officeicon.png"

class ContactUs extends Component {
  render() {
    const { history } = this.props;
    return (
      <Container active="contactus" history={history}>
        <div  style={{backgroundImage: `url(${contactmask})`,paddingTop:40}} className="bg centerText whiteColor">
          <h1>Get in touch</h1>
          <p>We would love to hear from you!</p>
        </div>
        <div className="center horizontal">
          <div className="box centerText boxMobile">
            <h4 className="primaryColor"><a href="tel:+919791848091" style={{color:"#01a4bf",textDecoration:"none"}}>+91 9791848091</a></h4>
            <p>We are available on WhatsApp as well</p>
          </div>
          <div className="box centerText">
            <h4 className="primaryColor">contact@moonproduct.in</h4>
            <p>We reply back in less than 8 hours</p>
          </div>
        </div>
        {/* <div className="container">
          <div className="boxL centerText">
            <h4 className="primaryColor">Feel free to write to us</h4>
            <input type="text" placeholder="Enter your name" className="line placeholder"/>
            <input type="email" placeholder="Enter your email id" className="line placeholder"/>
            <input type="phonenumber" placeholder="Enter your phone number" className="line placeholder"/>
            <input type="text" placeholder="Message" className="line placeholder"/>
            <button className="secondaryButton">Register</button>
            <p>y clicking on "Register”, you acknowledge having read our Private policy</p>
          </div>
        </div>  */}
        <div className="boxS centerText">
          <img src={officeicon} className="office-icon"/>
          <div className="Office">Office</div>
          <p className="SpringBoard-5th-F">
            91SpringBoard, 5th Floor, Dollars Colony
            Phase 4, JP Nagar, Bannerghatta Main Road
            Bengaluru, Karnataka 560076
          </p>
          {/* <div className="Show-on-map">Show on map</div>
          <div className="mobnum">
            +91 9791848091
            contact@moonproduct.in
          </div> */}
        </div>
    </Container> 
              
      );
      }       
      
      
      }

export default ContactUs;
