import React, {Component} from "react";
import { Container } from "../../components";
import "./home.css";
import bannerImage from "../../images/background/hero-bg_2019-01-28/herobg.png"
import icon1 from "../../icons/phone.png"
import icon2 from "../../icons/dollar.png"
import icon3 from "../../icons/files.png"
import icon4 from "../../icons/truck.png"
import icon5 from "../../icons/basket.png"
import icon6 from "../../icons/card.png"
import twoBlockImage from "../../images/assets/girl.png"
import greenbg from "../../images/assets/greenbg.png"
import line from "../../images/assets/line.png"
import collegebg  from "../../images/assets/collegebg.png"
import quote from "../../icons/quote.png"
import arrrowleft from "../../icons/arrowleft.png"
import arrowright from "../../icons/arrowright.png"
import bluebg from "../../images/background/bluebg.png"
import smallpattern from  "../../images/background/smallpattern.png"
import arrow from "../../images/assets/arrow.png"
import parachuteteve from "../../images/assets/parachuteteve.png"
import WhatsApp from "../../images/WhatsApp.png"
import MediaQuery from  'react-responsive';

class Home extends Component {
  constructor() {
    super();
  }

  render() {
    const { history } = this.props;
    return (
      <Container history={history}>
{/* Start of Main Banner */}
        <div className="whiteBg"><div className="container horizontal">
          <div className="vertical margin8">
            <h1 className="primaryColor">Making Businesses simpler</h1>
            <p>Dreaming of making your business happen in a snap? We are here to make them come true! Omni Channel Sales (Web, App, Social, Offline,etc...) and Inventory Management for Wholesalers and Manufacturers.</p>
            <p><button className="primaryButton" onClick={()=>{history.push("/register");window.scrollTo(0,0);}}>Register</button> or call <a href="https://wa.me/919791848091" target="_blank"><img src={WhatsApp} style={{height:32}}/></a></p>
          </div>
          <img className="photoImage" src={bannerImage}/>
        </div></div> 
{/* End of Main Banner */} 
{/* Start of Features Blocks */}   
        <div className="container">
          <h2 className="centerText margin2">Connect with your customers and sell your products more efficiently with an app designed just for you. But that is not all!</h2>
          <div className="horizontal rectangleCenter">
            <div className="rectangle whiteRectangle" >
              <img className="anythingCenter" src={icon1} style={{margin:"auto"}}/>
              <p className="primaryColor centerText">Ownership and Privacy</p>
              <p className="centerText">Do everything - from adding products in inventory to selling them to your customers, all from just a mobile app.</p>
            </div>
            <div className="rectangle whiteRectangle" >
              <img className="anythingCenter" src={icon2} style={{margin:"auto"}}/>
              <p className="primaryColor centerText">Multiple sales platform</p>
              <p className="centerText">Sell your products directly as well as on social media, e-commerce platforms, through resellers and more.</p>
            </div>
            <div className="rectangle whiteRectangle" >
              <img className="anythingCenter" src={icon3} style={{margin:"auto"}}/>
              <p className="primaryColor centerText">Inventory management</p>
              <p className="centerText">Manage and customise your inventory, make announcements about new products and deals.</p>
            </div> 
          </div>
          <div className="horizontal rectangleCenter">             
            <div className="rectangle whiteRectangle" >
              <img className="anythingCenter" src={icon4} style={{margin:"auto"}}/>
              <p className="primaryColor centerText">Logistics support</p>
              <p className="centerText">Our delivery partners can get your products to your customers at their doorstep. You can also integrate your delivery system.</p>
            </div>
            <div className="rectangle whiteRectangle" >
              <img className="anythingCenter" src={icon5} style={{margin:"auto"}}/>
              <p className="primaryColor centerText">Live tracking</p>
              <p className="centerText">From the moment it leaves you till it reaches your customer, you can track your products</p>
            </div>
            <div className="rectangle whiteRectangle" >
              <img className="anythingCenter" src={icon6} style={{margin:"auto"}}/>
              <p className="primaryColor centerText">Multiple Payment Gateways</p>
              <p className="centerText">We support all type of payments like online wallets, NEFT, net banking, e-wallets, credit and debit cards.</p>
            </div>           
          </div>
          {/* <button className="secondaryButton anythingCenter" onClick={()=>history.push("/features")}>See all features</button> */}
        </div>  
{/* End of Features Blocks */}
{/* Start of Features Blocks */}
        <div className="container horizontal">
          <img src={twoBlockImage} className="twoBlockImage photoImage"/>
          <div className="vertical margin8">
            <h2 className="primaryColor">We boost your supply chain management</h2>
            <ul>
              <li>We bring down your expense on logistics</li>
              <li>We provide a platform to tie-up with other retailers and wholesalers and sell more products</li>
              <li>You have us! So scale up without increasing the team strength</li>
              <li>We help you handle multiple warehouses with ease</li>
            </ul>
          </div>
        </div>
{/* End of Features Blocks */}
{/* Start of steps */}
        <div className="greenBg"><div className="container"><div className="paddingCenter16">
          <h2 className="centerText whiteColor padding2">Start selling more in 3 steps</h2>
          <div className="horizontal">
            <div className="rectangle greenRectangle">
              <p className="small-box centerText">step 1</p>
              <p className="centerText whiteColor">Choose us by clicking the REGISTER button</p>
            </div>
            <div className="rectangle greenRectangle">
              <p className="small-box centerText">step 2</p>
              <p className="centerText whiteColor">Set up your inventory in you app and ask your customers to use the app</p>
            </div>
            <div className="rectangle greenRectangle">
              <p className="small-box centerText">step 3</p>
              <p className="centerText whiteColor">We take charge from here. Start working on scaling up your business. We wish to see you grow fast!</p>
            </div>
          </div>
          <button className="blueButton anythingCenter" onClick={()=>{history.push("/register");window.scrollTo(0,0);}}>Register</button>
        </div></div></div>
{/* End of steps */}
{/* Start of Reviews */}
        <div className="container">
          <div className="horizontal whiteBox marginCenter24">
            <img className="photoImage" src={collegebg}/>
            <div className="vertical marginCenter8">
              <h2 className="primaryColor">See how Teve changes customer’s lives</h2>
              <p>"We are selling in multiple platforms but having our own sales plafrom created loyal customers for our brand. It really helped our business grow."</p>
              <div className="primaryColor">byzo, a product of eMag Solutions</div>
            </div>
          </div>
        </div> 
        {/* <div className="container horizontal">
          <div className="anythingCenter">
            <img src={arrrowleft}/>
            <img src={arrowright}/>
          </div>
        </div> */}
{/* End of Reviews */}        
{/* Start of CTA */}  
        <div className="blueBg"><div className="container padding2">
          <h2 className="centerText whiteColor">Your journey with us is just a call away!</h2>
          {/* <div className="horizontal">
            <form className="anythingCenter">
              <label>
                <input className="Rectangle-Copy" type="number" name="name" placeholder="Enter your number"/>
              </label>
              <input className="Rectangle-Copy-2 whiteColor" type="submit" value="Let Us Call You" onClick={()=>{history.push("/register");window.scrollTo(0,0);}}/>
            </form>
          </div> */}
          <p className="centerText whiteColor">Call us @ <a href="tel:+919791848091" style={{color:"#ffffff",textDecoration:"none"}}>+91 9791848091</a></p>
          <p className="centerText whiteColor">Your contact is our treasure. So we can't share it with anyone</p>
        </div></div>
{/* End of CTA */}
{/* Start of valueProposition */}  
        <div className="container"><div className="paddingCenter16 horizontal">
          <div className="rectangle whiteRectangle">
            <p className="centerText">Work hard and spend money on maintaining your inventory, sales and delivery management</p>
          </div>
          <div className="ballon" style={{backgroundImage: `url(${arrow})`}}>
            <img src={parachuteteve} className="teve-ballon"/>
          </div>
          <div className="rectangle whiteRectangle">
            <p className="primaryColor centerText weight600">Work smart with Teve and focus on your products while we take care of the rest</p>
            <button className="primaryButton anythingCenter" onClick={()=>{history.push("/register");window.scrollTo(0,0);}}>Register</button> 
          </div>        
        </div></div>
{/* End of Value Proposition */}                  
      </Container>        
      );
  }
}
export default Home;
