// Redux
import { applyMiddleware, combineReducers, createStore } from "redux";
import ReduxThunk from "redux-thunk";

import { register } from "./register.reducer";
import { endpoint } from "./endpoint.reducer";

// Middlewares
const middleware = () => {
  return applyMiddleware(ReduxThunk);
};

export default createStore(
  combineReducers({
    register,
    endpoint
  }),
  middleware()
);
