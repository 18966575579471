import React, { Component } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Route } from "react-router-dom";
import RootLoader from "./screens/root_loader";
import Home from "./screens/home";
import Feature from "./screens/features";
import ContactUs from "./screens/contact-us";
import registerPage from "./screens/register-page";
import { Header } from "./components";


const App = ({ store }) => (
  <Provider store={store}>
    <BrowserRouter>
      <>
        <Route exact path="/" component={RootLoader} />
        <Route exact path="/home" component={Home} />
        <Route exact path="/features" component={Feature} />
        <Route exact path="/contact" component={ContactUs} />
        <Route exact path="/register" component={registerPage}/>
        
        </>
    </BrowserRouter>
  </Provider>
);

export default App;
