import React, { Component } from "react";
import { Header, Footer } from "..";

export default class Container extends Component {
    render(){
        const { header=true , footer=true, history, active } = this.props;
        return (
            <div style={{backgroundColor: "#f8fffe"}}>
                <Header 
                    hide={header ? false : true } 
                    history={history}
                    active={active}/>
                    { this.props.children }
                <Footer hide={footer ? false : true }
                history={history}
                active={active}/>
            </div>
        );
    }
}