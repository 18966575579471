import React, { Component } from "react";
import "../../App.css";
import "./header.css";
import  Menu from "../menu";
import  MenuButton  from "../MenuButton";
import  MenuItem  from "../MenuItem";

export default class Header extends Component {
    constructor(props){
        super(props);
        this.state={
          menuOpen:false,
        }
      }
      handleMenuClick() {
        this.setState({menuOpen:!this.state.menuOpen});
      }
      handleLinkClick() {
        this.setState({menuOpen: false});
      }
    render(){
        const { hide, active="home", history } = this.props;
        // const menu = ['Home','Features','Contact Us']
        // const menuRedirect = ["","",""]
        // const menuItems = menu.map((val,index)=>{
        // return (
        //     <MenuItem 
        //         key={index} 
        //         delay={`${index * 0.1}s`}
        //         onClick={()=>{}}
        //     >
        //         {val}
        //     </MenuItem>)
        // });
        return(
            <div style={{display: hide? "none" : "block"}}>
                <div className="whiteBg">
                    <div style={{maxWidth:1080}} className="container">
                        <div className="ctaWeb">
                            <button style={{margin:22}} className="primaryButton floatRight" onClick={()=>{history.push("/register")}}>Register</button>
                            <p style={{float:"right"}} className="primaryColor nav floatRight"><a href="tel:+919791848091" style={{color:"#01a4bf",textDecoration:"none"}}>+91 9791848091</a></p>
                        </div>
                        <div className="horizontal">
                            <div className="logo">
                                <p onClick={()=>{history.push("/home")}}>
                                    <span style={{color:"#33475b"}}>Te</span>
                                    <span style={{color:"#ff7a59"}}>Ve</span>
                                </p>
                            </div>
                            {/* <MenuButton open={this.state.menuOpen} onClick={()=>this.handleMenuClick()} color='white'/>
                            <Menu open={this.state.menuOpen}>
                                {menuItems}
                            </Menu> */}
                            <div style={{display:"inline-block",margin:"0 auto"}}>
                                <p className={active==="home" ? "active nav floatRight" : "nav"} onClick={()=>{history.push("/home")}}>Home</p>
                                {/* <p className={active==="feature" ? "active nav floatRight" : "nav floatRight"} onClick={()=>{history.push("/features")}}>Features</p> */}
                                <p className={active==="contactus" ?"active nav " : "nav floatRight"} onClick={()=>{history.push("/contact")}}>Contact Us</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}