import { registerConstants } from "../constants";
import { registerService } from "../services";

export const registerActions = {
    register
};

function register(payload) {
    return dispatch => {
      dispatch(request());
      registerService
        .register(payload)
        .then(res => dispatch(success(res)), err => dispatch(error(err)));
    };
    function request() {
      return { type: registerConstants.REGISTER_REQUEST };
    }
    function success(res) {
      return { type: registerConstants.REGISTER_SUCCESS, res };
    }
    function error(err) {
      return { type: registerConstants.REGISTER_ERROR, err };
    }
  }
