import { registerConstants } from "../constants";

const initialState = {
  results: []
};

export function register(state = initialState, action) {
  switch (action.type) {
    case registerConstants.REGISTER_REQUEST:
      return {
        ...state,
        status: "REGISTER_REQUEST"
      };
    case registerConstants.REGISTER_SUCCESS:
      return {
        ...state,
        status: "REGISTER_SUCCESS",
        results: action.res,
      };
    case registerConstants.REGISTER_ERROR:
      return {
        ...state,
        status: "REGISTER_ERROR",
      }
    default:
      return state;
  }
}
